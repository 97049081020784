<template>
    <div style="margin: 0 0 20px 0;">
        <el-upload accept="docx" :show-file-list="true" :on-exceed="handleExceed" :on-success="handleAvatarSuccess"
            :on-error="error_up" :limit="1" class="upload-demo" drag action="/api/common/upload" :data="formData"
            :headers="header" :multiple="false">
            <div class="el-upload__text">
                <!-- <p class="text">将文件拖拽到此区域上传</p> -->
                <img src="@/assets/wordOp/upload.png" alt="" style="margin:25px 0 0 0;">
                <div class="el-upload__tip" slot="tip">仅支持.docx 此类文件</div>
            </div>
            <!-- <i class="el-icon-upload"></i> -->
            <div class=""></div>
            <!-- <em>点击上传</em> -->
        </el-upload>
    </div>
</template>

<script>
export default {
    mounted() {
        let $user_info = JSON.parse(localStorage.getItem('user_info'))
        if ($user_info) {
            this.getUserInfo()
            this.header.token = $user_info.token
        }
    },
    data() {
        return {
            header: {
                token: ''
            },
            // 请求参数
            formData: {
                type: 'read'
            },
            fillName: '',
            fileList: [],
        }
    },
    methods: {
        // 上传超出限制的钩子
        handleExceed(files, fileList) {
            this.$message.warning('当前限制选择 1个文件');
        },
        handleAvatarSuccess(res, file) {
            console.log(res);
            if (res.code) {
                this.fillName = res.data.content
                this.$emit('get_url', res.data.content)
            }

        },
        error_up(err, file, fileList) {
            // console.log(JSON.parse(err.message));
            this.$message({
                message: JSON.parse(err.message).msg,
                type: 'warning'
            })
        },
    },
}
</script>

<style lang=scss scoped>
::v-deep .el-upload-dragger {
    width: 100% !important;
    height: 110px !important;
    line-height: 40px !important;
    padding: 0 !important;
    border: 1px solid rgba(62, 160, 254, 1);
}

::v-deep .el-upload {
    width: 100%;

}

::v-deep .el-upload__tip {
    margin: 0;
    color: rgba(62, 160, 254, 1);
}
</style>